
import * as moment from "moment";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { shareReplay } from 'rxjs/operators'
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { jwtDecode } from 'jwt-decode';

import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';

export interface LoginModel {
    username: string,
    password: string,
    rememberMe: boolean
}

export interface AuthResult {
    token: string,
    expiration: Date
}

import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const apiReq = req.clone({ url: `${environment.baseUrl}${req.url}` });
        return next.handle(apiReq);
    }
}


@Injectable()
export class AuthGuardService implements CanActivateChild {

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivateChild(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {

        //check some condition  
        if (this.authService.isLoggedIn()) {
            return true;
        }
        this.router.navigate(['/login'], { queryParams: { redirect: state.url } });
        return false;
    }
}

@Injectable()
export class AccessGuard implements CanActivate, CanLoad {

    constructor(private authService: AuthService, private router: Router) {
    }

    canLoad() {
        if (this.authService.isLoggedIn()) {
            return true;
        }
        this.router.navigate(['/login'], { queryParams: { redirect: this.router.url } });
        return false;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isLoggedIn()) {
            return true;
        }
        this.router.navigate(['/login'], { queryParams: { redirect: state.url } });
        return false;
    }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {

        const idToken = localStorage.getItem("token");

        if (idToken) {
            const cloned = req.clone({
                headers: req.headers.set("Authorization",
                    "Bearer " + idToken)
            });

            return next.handle(cloned);
        }
        else {
            return next.handle(req);
        }
    }
}

@Injectable()
export class AuthService {

    constructor(private http: HttpClient, private router: Router) {
    }

    login(creds: LoginModel): Observable<boolean> {
        return this.http.post<AuthResult>('/auth/login', creds)
            .pipe(
                map((res: any) => {
                    this.setSession(res)
                    shareReplay();
                    return true;
                })
            );
    }

    private setSession(authResult: AuthResult) {
        const expiresAt = authResult.expiration;
        localStorage.setItem('token', authResult.token);
        localStorage.setItem("expiration", JSON.stringify(expiresAt.valueOf()));
    }

    logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("expiration");
        this.router.navigate(["/login"])
    }

    public isLoggedIn() {
        return moment().isBefore(this.getExpiration());
    }

    isLoggedOut() {
        return !this.isLoggedIn();
    }

    getExpiration() {
        const expiration = localStorage.getItem("expiration");
        const expiresAt = JSON.parse(expiration || '{}');
        return moment(expiresAt);
    }

    isMainAdmin() {
        if (this.isLoggedIn()) {
            const idToken = localStorage.getItem("token");
            const decoded = jwtDecode<MyTokenPayload>(idToken);
            const username = decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
            return username === "admin";
            //TODO: Just tmp workaround
        }
        return false;
    }
}

interface MyTokenPayload {
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
}